<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="通用套餐名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入通用套餐名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="标签">
          <a-checkbox-group
            v-decorator="['tags']"
            :options="tagsOptions"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="共享属性">
          <a-select
            @change="handleTrafficSharePropertyChange"
            v-decorator="['traffic_share_property', {
              rules: [{ required: true, message: '请选择共享属性' }]
            }]"
          >
            <a-select-option
              v-for="option in trafficSharePropertyOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="服务周期">
          <a-select
            v-decorator="['service_cycle', {
              rules: [{ required: true, message: '请选服务周期' }]
            }]"
          >
            <a-select-option
              v-for="option in serviceCycleOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="容量单位" v-if="isShowCapacityFields">
          <a-radio-group
            @change="handleCapacityUnitChange"
            v-decorator="['capacity_unit',{
              rules: [
                { required: true, message: '请选择容量单位' },
              ]
            }]"
          >
            <a-row style="margin-top: 9px">
              <a-col :span="8">
                <label>流量</label>
                <a-radio value="mb">
                  MB
                </a-radio>
                <a-radio value="gb">
                  GB
                </a-radio>
              </a-col>

              <a-col :span="8">
                <label>连接次数</label>
                <a-radio value="count">
                  次
                </a-radio>
              </a-col>
            </a-row>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="套餐容量" v-if="isShowCapacityFields">
          <a-input-number
            :min="0.01"
            :max="10000000"
            :step="0.01"
            :precision="2"
            style="width: 100%;"
            v-decorator="['package_capacity', {
              rules: [
                { required: true, message: '请输入套餐容量' },
                packageCapacityTypeRule
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="语音功能">
          <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
            <a-checkbox v-decorator="['is_has_voice_function', { valuePropName: 'checked' }]" />
          </a-form-item>

          <a-form-item
            v-if="isShowVoiceCapacitySetting"
            :style="{ display: 'inline-block', width: '85%', marginBottom: 0 }"
          >
            <a-space>
              <a-input-number
                :min="1"
                :max="999999"
                :step="1"
                :precision="0"
                style="width: 210px;"
                v-decorator="['voice_capacity', {
                  rules: [
                    { required: true, message: '请输入语音容量' },
                  ]
                }]"
              />
              <span>分钟</span>
            </a-space>
          </a-form-item>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createBaseCarrierProduct, findBaseCarrierProductServiceCycleOptions } from '@/api/base_carrier_product'
import { findTrafficSharePropertyOptions, findProductTagOptions } from '@/api/common_options'

export default {
  name: 'NewBaseCarrierProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'base_carrier_product', onValuesChange: this.onValuesChange }),
      submitting: false,
      isShowCapacityFields: true,
      isShowVoiceCapacitySetting: false,
      packageCapacityTypeRule: {
        type: 'number',
        message: '请输入 0.01 - 10000000 之间的数字'
      },
      tagsOptions: findProductTagOptions(),
      trafficSharePropertyOptions: findTrafficSharePropertyOptions(),
      serviceCycleOptions: findBaseCarrierProductServiceCycleOptions()
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onValuesChange(props, values) {
      if (values.is_has_voice_function !== undefined) {
        this.isShowVoiceCapacitySetting = values.is_has_voice_function
      }
    },

    // 是否是后向流量池
    isBackwardPool() {
      return this.form.getFieldValue('traffic_share_property') === 'backward'
    },

    // 处理 共享属性 变更
    handleTrafficSharePropertyChange() {
      this.$nextTick(() => {
        this.isShowCapacityFields = !this.isBackwardPool()
      })
    },

    // 处理 容量单位 变更
    handleCapacityUnitChange(e) {
      if (e.target.value === 'count') {
        this.packageCapacityTypeRule = {
          type: 'integer',
          message: '请输入 1 - 10000000 之间的整数'
        }
      } else {
        this.packageCapacityTypeRule = {
          type: 'number',
          message: '请输入 0.01 - 10000000 之间的数字'
        }
      }

      // 如果已经输入了值，则触发校验
      if (this.form.getFieldValue('package_capacity')) {
        this.$nextTick(() => {
          this.form.validateFields(['package_capacity'], { force: true })
        })
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          createBaseCarrierProduct(values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
