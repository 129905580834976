var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"通用套餐名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入通用套餐名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入通用套餐名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"标签"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tags']),expression:"['tags']"}],staticStyle:{"width":"100%"},attrs:{"options":_vm.tagsOptions}})],1),_c('a-form-item',{attrs:{"label":"共享属性"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['traffic_share_property', {
            rules: [{ required: true, message: '请选择共享属性' }]
          }]),expression:"['traffic_share_property', {\n            rules: [{ required: true, message: '请选择共享属性' }]\n          }]"}],on:{"change":_vm.handleTrafficSharePropertyChange}},_vm._l((_vm.trafficSharePropertyOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"服务周期"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['service_cycle', {
            rules: [{ required: true, message: '请选服务周期' }]
          }]),expression:"['service_cycle', {\n            rules: [{ required: true, message: '请选服务周期' }]\n          }]"}]},_vm._l((_vm.serviceCycleOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),(_vm.isShowCapacityFields)?_c('a-form-item',{attrs:{"label":"容量单位"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['capacity_unit',{
            rules: [
              { required: true, message: '请选择容量单位' } ]
          }]),expression:"['capacity_unit',{\n            rules: [\n              { required: true, message: '请选择容量单位' },\n            ]\n          }]"}],on:{"change":_vm.handleCapacityUnitChange}},[_c('a-row',{staticStyle:{"margin-top":"9px"}},[_c('a-col',{attrs:{"span":8}},[_c('label',[_vm._v("流量")]),_c('a-radio',{attrs:{"value":"mb"}},[_vm._v(" MB ")]),_c('a-radio',{attrs:{"value":"gb"}},[_vm._v(" GB ")])],1),_c('a-col',{attrs:{"span":8}},[_c('label',[_vm._v("连接次数")]),_c('a-radio',{attrs:{"value":"count"}},[_vm._v(" 次 ")])],1)],1)],1)],1):_vm._e(),(_vm.isShowCapacityFields)?_c('a-form-item',{attrs:{"label":"套餐容量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['package_capacity', {
            rules: [
              { required: true, message: '请输入套餐容量' },
              _vm.packageCapacityTypeRule
            ]
          }]),expression:"['package_capacity', {\n            rules: [\n              { required: true, message: '请输入套餐容量' },\n              packageCapacityTypeRule\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":0.01,"max":10000000,"step":0.01,"precision":2}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"语音功能"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '15%', marginBottom: 0 })},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_has_voice_function', { valuePropName: 'checked' }]),expression:"['is_has_voice_function', { valuePropName: 'checked' }]"}]})],1),(_vm.isShowVoiceCapacitySetting)?_c('a-form-item',{style:({ display: 'inline-block', width: '85%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['voice_capacity', {
                rules: [
                  { required: true, message: '请输入语音容量' } ]
              }]),expression:"['voice_capacity', {\n                rules: [\n                  { required: true, message: '请输入语音容量' },\n                ]\n              }]"}],staticStyle:{"width":"210px"},attrs:{"min":1,"max":999999,"step":1,"precision":0}}),_c('span',[_vm._v("分钟")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }